import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScheduleProvider } from './context/ScheduleContext';
import { AuthProvider } from './context/AuthContext';
import { ProjectProvider } from './context/ProjectContext.js';

import EssexSchedule from './template/essex/EssexSchedule';
import EssexEdit from './template/essex/EssexEdit';
import HarrowSchedule from './template/essex/HarrowSchedule';
import WellingtonEdit from './template/wellington/WellingtonEdit'
import LandingPage from './template/LandingPage';
import WellingtonList from './template/wellington/WellingtonList';
import EloraList from './template/wellington/EloraList';
import GuelphEdit from './template/guelph/GuelphEdit';
import WestEndFull1Template from './template/guelph/WestEndFull1';
import WestEndFull2Template from './template/guelph/WestEndFull2';
import VictoriaFullTemplate from './template/guelph/VictoriaFull';
import CentennialFullTemplate from './template/guelph/CentennialFull';
import ExhibitionFullTemplate from './template/guelph/ExhibitionFull';
import WestHantsEdit from './template/westhants/WestHantsEdit';
import WestHantsArena from './template/westhants/WestHantsArena';
import WestHantsField from './template/westhants/WestHantsField';
import WhitbyEdit from './template/whitby/WhitbyEdit';
import NotFound from './template/NotFound/404';
import Luther from './template/whitby/Luther/Luther.js';
import Arena2 from './template/whitby/IPSC/Arena2.js';
import Arena3 from './template/whitby/IPSC/Arena3.js';
import Arena4 from './template/whitby/IPSC/Arena4.js';
import Arena5 from './template/whitby/IPSC/Arena5.js';
import Arena6 from './template/whitby/IPSC/Arena6.js';
import IPSCLeft from './template/whitby/IPSCVertical/IPSCLeft.js';
import IPSCCentre from './template/whitby/IPSCVertical/IPSCCentre.js';
import IPSCRight from './template/whitby/IPSCVertical/IPSCRight.js';
import MCKLeft from './template/whitby/MCKVertical/MCKLeft.js';
import MCKCentre from './template/whitby/MCKVertical/MCKCentre.js';
import MCKRight from './template/whitby/MCKVertical/MCKRight.js';
import ArenaRooms from './template/whitby/IPSCFull/ArenaRooms.js';
import Project from './components/Project/Project.js';
import ProjectView from './components/Project/ProjectView.js';
import RecurringEventPage from './components/RecurringEvent/RecurringEventPage.js';

function App() {
  return (
    <ScheduleProvider>
      <ProjectProvider>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<LandingPage />} />

            <Route path="/harris-time" element={<Project />} />
            <Route path="/harris-time/projects" element={<ProjectView />} />

            <Route path="/essex/edit" element={<EssexEdit />} />
            <Route path="/essex/essex-1" element={<EssexSchedule />} />
            <Route path="/essex/harrow" element={<HarrowSchedule />} />
            <Route path="/essex/recurring-events/" element={<RecurringEventPage />} />

            <Route path="/wellington/edit" element={<WellingtonEdit />} />
            <Route path='/wellington/wellington-1' element={<WellingtonList />} />
            <Route path='/wellington/elora' element={<EloraList />} />

            <Route path='/guelph/edit' element={<GuelphEdit />}/>
            <Route path='/guelph/west-end-1' element={<WestEndFull1Template />} />
            <Route path='/guelph/west-end-2' element={<WestEndFull2Template />} />
            <Route path='/guelph/victoria' element={<VictoriaFullTemplate />} />
            <Route path='/guelph/centennial' element={<CentennialFullTemplate />} />
            <Route path='/guelph/exhibition' element={<ExhibitionFullTemplate />} />

            <Route path='/westhants/edit' element={<WestHantsEdit />} />
            <Route path='/westhants/westhants-arena' element={<WestHantsArena />} />
            <Route path='/westhants/westhants-field' element={<WestHantsField />} />

            <Route path='/whitby/edit' element={<WhitbyEdit />} />
            <Route path='/whitby/luther' element={<Luther />} />
            <Route path='/whitby/ipsc-2' element={<Arena2 />} />
            <Route path='/whitby/ipsc-3' element={<Arena3 />} />
            <Route path='/whitby/ipsc-4' element={<Arena4 />} />
            <Route path='/whitby/ipsc-5' element={<Arena5 />} />
            <Route path='/whitby/ipsc-6' element={<Arena6 />} />
            <Route path='/whitby/ipsc-left' element={<IPSCLeft />} />
            <Route path='/whitby/ipsc-centre' element={<IPSCCentre />} />
            <Route path='/whitby/ipsc-right' element={<IPSCRight />} />
            <Route path='/whitby/mck-left' element={<MCKLeft />} />
            <Route path='/whitby/mck-centre' element={<MCKCentre />} />
            <Route path='/whitby/mck-right' element={<MCKRight />} />
            <Route path='/whitby/ipsc-full' element={<ArenaRooms />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
      </ProjectProvider>
    </ScheduleProvider>
  );
}

export default App;
