import React from 'react';
import './arenarooms.css';

function ScheduleTable({ title, schedules }) {
  return (
    <div>
      <div className="ipsc-full-block_schedule_txt_title">{title}</div>
        {schedules.map((schedule, index) => (
        <div key={index} className="ipsc-full-block_schedule_txt_1">
            <span>{schedule.Team ? schedule.Team.substring(0, 27) : 'No Event'}</span>
            <span className="ipsc-full-is_right_1">HOME</span>
          <div className="ipsc-full-block_schedule_txt_2">
            <span>{schedule.Opponent ? schedule.Opponent.substring(0, 27) : ''}</span>
            <div className="ipsc-full-is_right_1">AWAY</div>
          </div>
      </div>
        ))}
    </div>
  );
}

export default ScheduleTable;
