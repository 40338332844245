import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import HeaderLeft from './HeaderLeft';
import Logo from './Logo';
import Title from './Title';
import ScheduleTable from './ScheduleTable';
import './style.css';
import ScoLogo from './SCO_Corporate_E_485.png';
import Okanagan from './OHG-HockeyOntario_Logo_CMYK.jpg';

function IPSCLeft() {
    const { fetchSchedulesWhitby } = useContext(ScheduleContext);
    
      useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesWhitby();
            }
        }, 60000)
    
        return () => clearInterval(interval);
      });

    useEffect(() => {
            document.body.classList.add('ipsc-vert-app-container');
    
            return () => {
                document.body.classList.remove('ipsc-vert-app-container');
                };
        }, [])

    return (
        <div className="App">
        <HeaderLeft />
        <Logo bottomSrc={Okanagan} classNameTop="ipsc-vert-logo_top" classNameBottom="ipsc-vert-logo_bottom"/>
        <Title text="Rink 1" className="ipsc-vert-title1" />
        <ScheduleTable id="content1" className="ipsc-vert-midle_main1" arenaId="IPSC (1)" />
        <Title text="Arena 4" className="ipsc-vert-title2" />
        <ScheduleTable id="content2" className="ipsc-vert-midle_main2" arenaId="IPSC (4)" />
        </div>
    );
}

export default IPSCLeft;