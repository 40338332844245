import React, { useState, useEffect } from 'react';
import './Luther.css';
import WhitbyLogo from './Whitby_Logo_HOR_NEG_WHT.png';

function Header() {
  const currentDate = new Date();
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const formatTime = () => {
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };

  const [currentTime, setCurrentTime] = useState(new Date());
      
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
  
        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);
  
    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
  
    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',

        hour12: true
    });

  return (
    <div className="luther-header_top">
      <img className="luther-header_txt_left" src={WhitbyLogo} alt="Whitby Logo" />
      <div id="my_hour" className="luther-header_right">{formattedTime}</div>
      <div id="my_day" className="luther-header_right_bottom">
        {formattedDate}
      </div>
    </div>
  );
}

export default Header;