import React, { useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import '../Schedule.css';
import VenueScheduleEditElora from './VenueScheduleEditElora';
import ManageAndUploadAdsElora from '../../Ads/Wellington/UploadAdsElora';
import RoomEditElora from '../../Room/Wellington/RoomEditElora';


export default function ScheduleEditElora() {
  const { schedulesWellington, selectedDateWellington } = useContext(ScheduleContext);

  const filteredSchedules = (venueName) => {
    // Convert selectedDateWellington to EST
    const estDate = new Date(selectedDateWellington).toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    return schedulesWellington.filter(s => s.Venue === venueName && s.Date === estDate);
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3">
        <h1 className='kanit-regular text-center'>JECC</h1>
        <h2 className='kanit-regular'>Pad Booking</h2>
        <VenueScheduleEditElora venueName="Elora Pad" schedules={filteredSchedules('Elora Pad')} />
        <h2 className='kanit-regular'>Room Booking</h2>
        <RoomEditElora venueName="Banquet Hall" schedules={filteredSchedules('Banquet Hall')} />
        <br></br>
        <ManageAndUploadAdsElora />
      </div>
    </div>
  );
}
