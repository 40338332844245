import React, { createContext, useState, useEffect } from 'react';

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const [projects, setProjects] = useState([]); // Stores all projects
    const CLOUD_RUN_URL = 'https://arenascheduling.uc.r.appspot.com/api/harris-time';

    // Fetch all projects
    const fetchProjects = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/projects/`, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setProjects(data); // Store the fetched projects
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    // Add a new project
    const addProject = async (project) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/projects/add/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(project),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newProject = await response.json();
            setProjects((prevProjects) => [...prevProjects, newProject]);
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    // Update an existing project
    const updateProject = async (id, updatedProjectData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/projects/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedProjectData),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedProject = await response.json();
            setProjects((prevProjects) =>
                prevProjects.map((project) =>
                    project.id === id ? updatedProject : project
                )
            );
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    // Delete a project
    const deleteProject = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/projects/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setProjects((prevProjects) =>
                prevProjects.filter((project) => project.id !== id)
            );
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    // UseEffect to fetch projects on component mount
    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <ProjectContext.Provider
            value={{
                projects,
                fetchProjects,
                addProject,
                updateProject,
                deleteProject,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};
