import React from 'react';
import './arenarooms.css';

function CenterContent({ title, subtitle, ticker }) {
  return (
    <div>
      <div className="ipsc-full-center">{title}</div>
      <div className="ipsc-full-center_small">{subtitle}</div>
      <div className="ipsc-full-bottom">
        <div id="ticker" className="ipsc-full-center_ticker">{ticker}</div>
      </div>
    </div>
  );
}

export default CenterContent;
