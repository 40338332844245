import React from 'react';
import RecurringEvent from './RecurringEvent';
import HeaderEdit from '../Header/Essex/HeaderEdit';
import {Link} from 'react-router-dom';
import RecurringEventsHarrow from './RecurringEventHarrow';

export default function RecurringEventPage() {
    return (
        <>
            <HeaderEdit />
            {/* Recurring Events Navigation Link */}
            <div className="row mt-4 justify-content-center">
              <div className="col-auto">
                <Link to="/essex/edit/" className="btn btn-outline-dark">
                  Back to Edit Page
                </Link>
              </div>
            </div>
            <h1 className='m-3 kanit-regular'>Essex Recurring Events</h1>
            <RecurringEvent venueName='Essex Arena (Libro)' />
            <RecurringEvent venueName='Essex Arena (Shaheen)' />
            <RecurringEvent venueName='Barnett Board Room' />
            <RecurringEvent venueName='Shaheen Community Room' />
            <br></br>
            <h1 className='m-3 kanit-regular'>Harrow Recurring Events</h1>
            <RecurringEventsHarrow venueName='Harrow Arena' />
            <RecurringEventsHarrow venueName='Large Community Room' />
            <RecurringEventsHarrow venueName='Small Community Room' />
            <br></br>
            <footer className="bg-dark text-white text-center footer py-4 mt-3 ">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
}