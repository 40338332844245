import React from 'react';
import './arenarooms.css';

function BookingSection({ room, row, schedules }) {

  function formatTimeTo12Hour(time24) {
    if (!time24) return ''; // Handle empty or undefined time
    const [hour, minute] = time24.split(':').map(Number);
    const amPm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${formattedHour}:${minute.toString().padStart(2, '0')}${amPm}`;
  }

  return (
    <div className="ipsc-full-booking">
        <div className="ipsc-full-booking_txt_title">{room}</div>
        {schedules.map((schedule, index) => 
          <div key={index} id={`bokking_${row}1`}><strong>{formatTimeTo12Hour(schedule.StartTime)}</strong>  {schedule.Team ? schedule.Team.substring(0, 34) : 'There is currently no booking'}</div>
        )}
    </div>
  );
}

export default BookingSection;