import React, { useState, useEffect } from 'react';
import './arenarooms.css';
import WhitbyLogo from './Whitby_Logo_HOR_NEG_WHT.png';

function Header() {
  const [currentTime, setCurrentTime] = useState(new Date());
    
  useEffect(() => {
      const intervalId = setInterval(() => {
          setCurrentTime(new Date());
      }, 1000);

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const formattedDate = currentTime.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
  });

  const formattedTime = currentTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
  });

  return (
    <div className="ipsc-full-header_top">
      <img
        className="ipsc-full-header_txt_left"
        src={WhitbyLogo}
        alt="Whitby Logo"
      />
      <div id="my_hour" className="ipsc-full-header_right">{formattedTime}</div>
      <div id="my_day" className="ipsc-full-header_right_bottom">
        {formattedDate}
      </div>
    </div>
  );
}

export default Header;