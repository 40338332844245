import React from 'react';
import './style.css';

function Logo({ topSrc, bottomSrc, classNameTop, classNameBottom }) {
  return (
    <>
      <div>
        {topSrc && <img className={classNameTop} src={topSrc} alt="Top Logo" />}
      </div>
      <div>
        <img className={classNameBottom} src={bottomSrc} alt="Bottom Logo" />
      </div>
    </>
  );
}

export default Logo;