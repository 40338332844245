import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import '../Header.css'; // Make sure to import your CSS file
import { useNavigate } from 'react-router-dom';

export default function HeaderEdit() {
    const [currentTime, setCurrentTime] = useState(new Date());
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark bg-gradient py-3">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <img src="/logo.png" alt="Logo" width="270" height="90" className="d-inline-block align-text-top rounded img-thumbnail" />
                </div>
                <div className="col-12 col-md-6 text-center mb-2 mb-md-0">
                    <span className="navbar-text display-4 display-md-2 text-white kanit-regular">
                        Essex Centre Sports Complex
                    </span>
                </div>
                <div className="d-flex flex-column align-items-end text-md-end">
                    <span className="navbar-text h1 kanit-regular" style={{ color: '#FFFFFF' }}>
                        {formattedDate}
                    </span>
                    <span className="navbar-text h2 kanit-regular" style={{ color: '#FFFFFF' }}>
                        {formattedTime}
                    </span>
                    {user && (
                        <button
                            className="btn btn-outline-light mt-2"
                            onClick={() => logout(navigate)}
                        >
                            Logout
                        </button>
                    )}
                </div>
            </div>
        </nav>
    );
}
