import React, { useEffect, useContext, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import Header from './Header';
import CenterContent from './CenterContent';
import BookingSection from './BookingSection';

import './arenarooms.css';
import ScheduleTable from './ScheduleTable';

function ArenaRooms() {
  const { schedulesWhitby, fetchSchedulesWhitby } = useContext(ScheduleContext); // Use context to get schedules and teams
  const [arena1Schedules, setArena1Schedules] = useState([]);
  const [arena2Schedules, setArena2Schedules] = useState([]);
  const [arena3Schedules, setArena3Schedules] = useState([]);
  const [arena4Schedules, setArena4Schedules] = useState([]);
  const [arena5Schedules, setArena5Schedules] = useState([]);
  const [arena6Schedules, setArena6Schedules] = useState([]);

  const [whitneySchedules, setWhitneySchedules] = useState([]);
  const [aopSchedules, setAopSchedules] = useState([]);
  const [largeMezzanineSchedules, setLargeMezzanineSchedules] = useState([]);
  const [smallMezzanineSchedules, setSmallMezzanineSchedules] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentHour = new Date().getHours();
      if (!(currentHour >= 0 && currentHour < 6)) {
        fetchSchedulesWhitby();
      }
    }, 60000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    const now = new Date();

    // Filter schedules for each venue
    const filterSchedules = (venueName) => {
      return schedulesWhitby.filter((s) => {
        if (s.Venue === venueName) {
          const startTime = new Date(`${s.Date}T${s.StartTime}:00`);
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`);

          // Check if now is between startTime and endTime
          return now >= startTime && now <= endTime;
        }
        return false;
      });
    };

    setArena1Schedules(filterSchedules('IPSC (1)'));
    setArena2Schedules(filterSchedules('IPSC (2)'));
    setArena3Schedules(filterSchedules('IPSC (3)'));
    setArena4Schedules(filterSchedules('IPSC (4)'));
    setArena5Schedules(filterSchedules('IPSC (5)'));
    setArena6Schedules(filterSchedules('IPSC (6)'));

    setWhitneySchedules(filterSchedules('Whitney Hall'));
    setAopSchedules(filterSchedules('AOP Boardroom'));
    setLargeMezzanineSchedules(filterSchedules('Large Mezzanine Boardroom'));
    setSmallMezzanineSchedules(filterSchedules('Small Mezzanine Boardroom'));
  }, [schedulesWhitby]); // Rerender when schedules or teams context changes

  useEffect(() => {
    document.body.classList.add('ipsc-full-app-container');

    return () => {
      document.body.classList.remove('ipsc-full-app-container');
    };
  }, []);

  return (
    <div className="App">
      <Header />
      <CenterContent
        title="Iroquois Park Sports Centre"
        subtitle="Current Schedule"
        ticker="Welcome to Iroquois Park Sports Centre"
      />

      <div id="main_txt" className="ipsc-full-midle">
        <div className="ipsc-full-split_left ipsc-full-left">
          <BookingSection room="Whitney Hall" row="1" schedules={whitneySchedules} />
          <BookingSection room="AOP Boardroom" row="2" schedules={aopSchedules} />
          <BookingSection room="Large Mezzanine Boardroom" row="3" schedules={largeMezzanineSchedules} />
          <BookingSection room="Small Mezzanine Boardroom" row="4" schedules={smallMezzanineSchedules} />
        </div>

        <div className="ipsc-full-split_right ipsc-full-right">
          <div className="ipsc-full-block_event">
            <div id="arena1" className="ipsc-full-block_schedule_left">
              <ScheduleTable title="Rink 1" schedules={arena1Schedules} />
            </div>
            <div id="arena2" className="ipsc-full-block_schedule_right">
              <ScheduleTable title="Rink 2" schedules={arena2Schedules} />
            </div>
          </div>

          <div className="ipsc-full-block_event">
            <div id="arena3" className="ipsc-full-block_schedule_left">
              <ScheduleTable title="Rink 3" schedules={arena3Schedules} />
            </div>
            <div id="arena4" className="ipsc-full-block_schedule_right">
              <ScheduleTable title="Rink 4" schedules={arena4Schedules} />
            </div>
          </div>

          <div className="ipsc-full-block_event">
            <div id="arena5" className="ipsc-full-block_schedule_left">
              <ScheduleTable title="Rink 5" schedules={arena5Schedules} />
            </div>
            <div id="arena6" className="ipsc-full-block_schedule_right">
              <ScheduleTable title="Rink 6" schedules={arena6Schedules} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArenaRooms;
