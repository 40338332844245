import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import ScheduleListHarrow from '../../components/Schedule/Essex/ScheduleListHarrow';
import RoomListHarrow from '../../components/Room/Essex/RoomListHarrow';
import HeaderHarrow from '../../components/Header/Essex/HeaderHarrow';
import JumbotronHarrow from '../../components/Jumbotron/Essex/JumbotronHarrow';

export default function HarrowSchedule() {
    const { fetchSchedulesHarrow, fetchJumbotronHarrow } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesHarrow();
                fetchJumbotronHarrow();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return(
        <>
            <HeaderHarrow />
            <ScheduleListHarrow />
            <RoomListHarrow />
            <JumbotronHarrow />
        </>
    );
}