import React, { useEffect, useState, useContext } from 'react';
import './mck.css';
import { ScheduleContext } from '../../../context/ScheduleContext';

function ArenaSchedule({ title, arenaId, className }) {
    const { schedulesWhitby } = useContext(ScheduleContext); // Use context to get schedules and teams
    const [arenaSchedules, setArenaSchedules] = useState([]);
    
    useEffect(() => {
        const now = new Date();
        const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

        // Filter schedules for each venue
        const filterSchedules = (venueName) => {
        return schedulesWhitby.filter(s => {
            if (s.Venue === venueName && s.Date === today) {
            // Combine the schedule date and EndTime to create a complete Date object
            const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
            const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

            return now <= thirtyMinutesAfterEnd;
            }
            return false;
        });
        };

        const arena = ensureNEntries(filterSchedules(arenaId));
        setArenaSchedules(arena);

    }, [schedulesWhitby, arenaId]); // Rerender when schedules or teams context changes

    function ensureNEntries(schedules) {
        // Sort schedules by Date and StartTime
        schedules.sort((a, b) => {
        const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
        const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
        return dateA - dateB;
        });

        // Limit to the next 15 events
        const limitedSchedules = schedules.slice(0, 10);

        // Add placeholders if less than 15 events are found
        const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
        while (limitedSchedules.length < 10) {
        limitedSchedules.push(placeholder);
        }

        return limitedSchedules;
    }

    function formatTimeTo12Hour(time24) {
        if (!time24) return ''; // Handle empty or undefined time
        const [hour, minute] = time24.split(':').map(Number);
        const amPm = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
        return `${formattedHour}:${minute.toString().padStart(2, '0')}${amPm}`;
      }

    return (
        <div className={className}>
        <div className="mck-midle">
            <div className="divTable mck-blueTable">
            <div className="divTableHeading">
                <div className="divTableRow">
                <div className="divTableHead width10">Time</div>
                <div className="divTableHead width80">Teams</div>
                <div className="divTableHead width10">Room</div>
                </div>
            </div>
            <div className="divTableBody">
                {arenaSchedules.map((event, index) => (
                <div className="divTableRow" key={index}>
                    <div className="divTableCell width10">{formatTimeTo12Hour(event.StartTime)}</div>
                    <div className="divTableCell width80">
                    {event.Team}<br />
                    {event.Opponent}
                    </div>
                    <div className="divTableCell width10">
                    {event.HomeRoom}<br />
                    {event.AwayRoom}
                    </div>
                </div>
                ))}
            </div>
            </div>
        </div>
        </div>
    );
}

export default ArenaSchedule;