import React from 'react';
import './Arena.css';

function CenterContent({ title, subtitle }) {
  return (
    <div >
      <div className="ipsc-center">{title}</div>
      <div className="ipsc-center_small">{subtitle}</div>
    </div>
  );
}

export default CenterContent;