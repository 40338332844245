import React, { useContext, useState, useEffect } from 'react';
import { ProjectContext } from '../../context/ProjectContext';

export default function ProjectEdit() {
    const { projects, addProject, updateProject, deleteProject } = useContext(ProjectContext);
    const [editableProjects, setEditableProjects] = useState([]);
    const [newProject, setNewProject] = useState({
        workOrder: '',
        name: '',
        overview: '',
        orderDate: '',
    });

    useEffect(() => {
        setEditableProjects(projects);
    }, [projects]);

    const handleFieldChange = (index, field, value) => {
        const updatedProjects = editableProjects.map((project, idx) =>
            idx === index ? { ...project, [field]: value } : project
        );
        setEditableProjects(updatedProjects);
    };

    const handleAddProject = async () => {
        try {
            await addProject(newProject);
            setNewProject({ workOrder: '', name: '', overview: '', orderDate: '' });
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    const handleUpdateProject = async (index) => {
        try {
            const project = editableProjects[index];
            if (!project || !project.id) {
                throw new Error('Invalid project or missing ID');
            }
            await updateProject(project.id, project);
        } catch (error) {
            console.error('Error updating project:', error.message);
        }
    };

    const handleDeleteProject = async (id) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            try {
                await deleteProject(id);
            } catch (error) {
                console.error('Error deleting project:', error);
            }
        }
    };

    return (
        <div className="container mt-4 p-4">
            <h1 className='text-center'>Manage Projects</h1>
            <hr />
            <h2 className="text-muted">Add New Project</h2>
            <div className="row">
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Work Order"
                        value={newProject.workOrder}
                        onChange={(e) => setNewProject({ ...newProject, workOrder: e.target.value })}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={newProject.name}
                        onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Overview"
                        value={newProject.overview}
                        onChange={(e) => setNewProject({ ...newProject, overview: e.target.value })}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        type="date"
                        className="form-control"
                        value={newProject.orderDate}
                        onChange={(e) => setNewProject({ ...newProject, orderDate: e.target.value })}
                    />
                </div>
                <div className="col-md-3 mt-3">
                    <button className="btn btn-success" onClick={handleAddProject}>
                        Add Project
                    </button>
                </div>
            </div>
            <hr />
            <h2 className="text-muted">Existing Projects</h2>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Work Order</th>
                        <th>Name</th>
                        <th>Overview</th>
                        <th>Order Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {editableProjects.map((project, index) => (
                        <tr key={project.id || index}>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={project.workOrder}
                                    onChange={(e) => handleFieldChange(index, 'workOrder', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={project.name}
                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={project.overview}
                                    onChange={(e) => handleFieldChange(index, 'overview', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={project.orderDate}
                                    onChange={(e) => handleFieldChange(index, 'orderDate', e.target.value)}
                                />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary me-2"
                                    onClick={() => handleUpdateProject(index)}
                                >
                                    Save
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteProject(project.id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
