// components/ScheduleTable.js
import React, { useEffect, useState, useContext } from 'react';
import './Arena.css';
import { ScheduleContext } from '../../../context/ScheduleContext';

function ScheduleTableArena5() {
  const { schedulesWhitby, teamsWhitby } = useContext(ScheduleContext); // Use context to get schedules and teams
    const [arenaSchedules, setArenaSchedules] = useState([]);
    const [teamMap, setTeamMap] = useState({});
  
    useEffect(() => {
      const now = new Date();
      const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });
  
      // Create a map of team codes to team names
      const teamMapping = teamsWhitby.reduce((map, team) => {
        map[team.Code] = team.Name;
        return map;
      }, {});
      setTeamMap(teamMapping);
  
      // Filter schedules for each venue
      const filterSchedules = (venueName) => {
        return schedulesWhitby.filter(s => {
          if (s.Venue === venueName && s.Date === today) {
            // Combine the schedule date and EndTime to create a complete Date object
            const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
            const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds
  
            return now <= thirtyMinutesAfterEnd;
          }
          return false;
        });
      };
  
      const arena = ensureNEntries(filterSchedules('IPSC (5)'));
      setArenaSchedules(arena);
    }, [schedulesWhitby, teamsWhitby]); // Rerender when schedules or teams context changes
  
    function ensureNEntries(schedules) {
      // Sort schedules by Date and StartTime
      schedules.sort((a, b) => {
        const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
        const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
        return dateA - dateB;
      });
  
      // Limit to the next 8 events
      const limitedSchedules = schedules.slice(0, 8);
    
      // Add placeholders if less than 8 events are found
      const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
      while (limitedSchedules.length < 8) {
        limitedSchedules.push(placeholder);
      }
    
      return limitedSchedules;
    }

    function formatTimeTo12Hour(time24) {
      if (!time24) return ''; // Handle empty or undefined time
      const [hour, minute] = time24.split(':').map(Number);
      const amPm = hour >= 12 ? 'PM' : 'AM';
      const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
      return `${formattedHour}:${minute.toString().padStart(2, '0')}${amPm}`;
    }

  return (
    <div id='main_txt' className="ipsc-midle_main">
      <div className="ipsc-midle">
        <div className="divTable ipsc-blueTable">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead width10">Time</div>
              <div className="divTableHead width35">Home Team</div>
              <div className="divTableHead width10">Room</div>
              <div className="divTableHead width35">Away Team</div>
              <div className="divTableHead width10">Room</div>
            </div>
          </div>
          <div className="divTableBody">
          {arenaSchedules.map((schedule, index) => (
              <div className="divTableRow" key={index}>
                <div className="divTableCell">{formatTimeTo12Hour(schedule.StartTime)}</div>
                <div className="divTableCell">{teamMap[schedule.Team] || schedule.Team || ""}</div>
                <div className="divTableCell">{schedule.HomeRoom}</div>
                <div className="divTableCell">{teamMap[schedule.Opponent] || schedule.Opponent || ""}</div>
                <div className="divTableCell">{schedule.AwayRoom}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleTableArena5;