import React, { useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueScheduleEdit from './VenueScheduleEdit';
import DateSelector from '../../DatePicker/DateSelector';
import ExportCSV from '../../FileUpload/ExportCSV';
import ImportCSV from '../../FileUpload/ImportCSV';
import '../Schedule.css';
import RoomEdit from '../../Room/Whitby/RoomEdit';

export default function ScheduleEdit() {
  const { schedulesWhitby, selectedDateWhitby, setSelectedDateWhitby, saveSchedulesWhitby } = useContext(ScheduleContext);

  // Extract the unique venue names from the data for filtering
  const uniqueVenues = [...new Set(schedulesWhitby.map(schedule => schedule.Venue))];

  const filteredSchedules = (venueName) => {
    // Convert selectedDateWhitby to EST
    const estDate = new Date(selectedDateWhitby).toLocaleDateString('en-CA', { timeZone: 'America/Halifax' });

    return schedulesWhitby.filter(s => s.Venue === venueName && s.Date === estDate);
  };

  const handleImportData = (importedData) => {
    console.log("Imported Data:", importedData); // Log the imported data

    // Validate and filter the imported data
    const validData = importedData.filter(item => item.Date && item.StartTime && item.EndTime && item.Venue);

    if (validData.length === 0) {
      console.error("No valid data to import.");
      alert("No valid data to import. Please check your CSV file.");
      return;
    }

    const venues = [...new Set(validData.map(item => item.Venue))];

    venues.forEach(venue => {
      const venueSchedules = validData.filter(item => item.Venue === venue);
      saveSchedulesWhitby(venue, venueSchedules);
    });

    alert("File is uploading..."); 
    setTimeout(() => {
      alert("File upload is done.");
      window.location.reload();
    }, 5000);
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3 text-center">
        <DateSelector selectedDate={selectedDateWhitby} onDateChange={setSelectedDateWhitby} />
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-auto">
          {/* Pass the schedule data and unique venue names to ExportCSV */}
          <ExportCSV data={schedulesWhitby} venues={uniqueVenues} />
        </div>
        <div className="col-auto">
          <ImportCSV onImport={handleImportData} />
        </div>
      </div>
      <div className="row mt-3">
        <h1 className='kanit-regular'>Iroquois Park Sports Centre</h1>
        <VenueScheduleEdit venueName="IPSC (1)" schedules={filteredSchedules('IPSC (1)')} />
        <VenueScheduleEdit venueName="IPSC (2)" schedules={filteredSchedules('IPSC (2)')} />
        <VenueScheduleEdit venueName="IPSC (3)" schedules={filteredSchedules('IPSC (3)')} />
        <VenueScheduleEdit venueName="IPSC (4)" schedules={filteredSchedules('IPSC (4)')} />
        <VenueScheduleEdit venueName="IPSC (5)" schedules={filteredSchedules('IPSC (5)')} />
        <VenueScheduleEdit venueName="IPSC (6)" schedules={filteredSchedules('IPSC (6)')} />
        <br></br>
        <h1 className='kanit-regular'>McKinney Centre</h1>
        <VenueScheduleEdit venueName="McKinney (1)" schedules={filteredSchedules('McKinney (1)')} />
        <VenueScheduleEdit venueName="McKinney (2)" schedules={filteredSchedules('McKinney (2)')} />
        <VenueScheduleEdit venueName="McKinney (3)" schedules={filteredSchedules('McKinney (3)')} />
        <br></br>
        <h1 className='kanit-regular'>Luther Vipond Memorial</h1>
        <VenueScheduleEdit venueName="Luther Vipond Memorial" schedules={filteredSchedules('Luther Vipond Memorial')} />
        <br></br>
        <h1 className='kanit-regular'>Room Booking</h1>
        <RoomEdit venueName="Whitney Hall" schedules={filteredSchedules('Whitney Hall')} />
        <RoomEdit venueName="AOP Boardroom" schedules={filteredSchedules('AOP Boardroom')} />
        <RoomEdit venueName="Large Mezzanine Boardroom" schedules={filteredSchedules('Large Mezzanine Boardroom')} />
        <RoomEdit venueName="Small Mezzanine Boardroom" schedules={filteredSchedules('Small Mezzanine Boardroom')} />
        <br></br>
      </div>
    </div>
  );
}
