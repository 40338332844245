import React, {useEffect, useContext} from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTableArena3 from './ScheduleTableArena3';
import './Arena.css';

function Arena3() {
  const { fetchSchedulesWhitby } = useContext(ScheduleContext);
  
    useEffect(() => {
      const interval = setInterval(() => {
          const currentHour = new Date().getHours();
          if (!(currentHour >= 0 && currentHour < 6)) {
              fetchSchedulesWhitby();
          }
      }, 60000)
  
      return () => clearInterval(interval);
    });

    useEffect(() => {
            document.body.classList.add('ipsc-app-container');
    
            return () => {
                document.body.classList.remove('ipsc-app-container');
              };
        }, [])

  return (
    <div className="App">
      <Header />
      <div className="ipsc-title">Arena 3</div>
      <CenterContent title="Iroquois Park Sports Centre" subtitle="Current Schedule" />
      <ScheduleTableArena3/>
    </div>
  );
}

export default Arena3;