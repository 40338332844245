import React from 'react';
import './style.css';
import WhitbyLogo from './Whitby_Logo_HOR_NEG_WHT.png';

function HeaderLeft() {
  return (
    <div className="ipsc-vert-header_top">
      <img className="ipsc-vert-header_txt_left" src={WhitbyLogo} alt="Whitby Logo" />
    </div>
  );
}

export default HeaderLeft;