import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueSchedule from './VenueSchedule';
import '../Schedule.css';

function WestEndFull2() {
  const { schedulesGuelph, teamsGuelph } = useContext(ScheduleContext); // Use context to get schedules and teams
  const [rink2Schedules, setRink2Schedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    // Create a map of team codes to team names
    const teamMapping = teamsGuelph.reduce((map, team) => {
      map[team.Code] = team.Name;
      return map;
    }, {});
    setTeamMap(teamMapping);

    // Filter schedules for each venue
    const filterSchedules = (venueName) => {
      return schedulesGuelph.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          // Combine the schedule date and EndTime to create a complete Date object
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    const rink2 = ensureNEntries(filterSchedules('West End Recreation Centre (2)'));
    setRink2Schedules(rink2);

  }, [schedulesGuelph, teamsGuelph]);

  function ensureNEntries(schedules) {
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });

    // Limit to the next 5 events
    const limitedSchedules = schedules.slice(0, 6);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 6) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid d-flex flex-column mt-3">
      <div className="row flex-grow-1">
        <div className="col">
          <VenueSchedule venueName="Rink 2" schedules={rink2Schedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}

export default WestEndFull2;
