import React from 'react';
import '../Schedule.css';

function formatTime(time24) {
  if (!time24) return ''; // Return an empty string if time24 is undefined or null
  const [hour, minute] = time24.split(':');
  let hour12 = parseInt(hour, 10);
  const period = hour12 >= 12 ? 'PM' : 'AM';
  hour12 = hour12 % 12 || 12; // Convert hour to 12-hour format, 0 becomes 12
  return `${hour12}:${minute} ${period}`;
}

function combineTimes(startTime, endTime) {
  const formattedStart = formatTime(startTime);
  const formattedEnd = formatTime(endTime);
  return `${formattedStart}\n${formattedEnd}`;
}

export default function VenueSchedule({ venueName, schedules, teamMap, sponsorLogo }) {
  return (
    <div className="card">
      <div className="card-header text-white h1 kanit-regular d-flex align-items-center justify-content-between" style={{ backgroundColor: 'rgb(0, 167, 224)', fontWeight: 'bold', fontSize: '2.5rem' }}>
        {venueName}
        {sponsorLogo && <img src={sponsorLogo} alt={`${venueName} Sponsor`} className="sponsor-logo" />}
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered text-center">
          <thead>
            <tr className='h3' style={{fontWeight: 'bold', fontSize: '2.3rem'}}>
              <th className="time-column">Time</th>
              <th className="team-column">Home Team</th>
              <th className="room-column">Room</th>
              <th className="team-column">Away Team</th>
              <th className="room-column">Room</th>
            </tr>
          </thead>
          <tbody className='h3' style={{fontWeight: 'bold', fontSize: '2.3rem', lineHeight: '1.2'}}>
            {schedules.map((schedule, index) => (
              <tr key={index} style={{height: '100px'}}>
                <td className="time-column" style={{ whiteSpace: 'pre-wrap', fontSize: '2.2rem', fontWeight: 'bold' }}>{combineTimes(schedule.StartTime, schedule.EndTime)}</td>
                <td className="team-column" style={{fontSize: '2.2rem', fontWeight: 'bold'}}>{teamMap[schedule.Team] || schedule.Team || ""}</td>
                <td className="room-column" style={{fontSize: '2.2rem', fontWeight: 'bold'}}>{schedule.HomeRoom || ""}</td>
                <td className="team-column" style={{fontSize: '2.2rem', fontWeight: 'bold'}}>{teamMap[schedule.Opponent] || schedule.Opponent || ""}</td>
                <td className="room-column" style={{fontSize: '2.2rem', fontWeight: 'bold'}}>{schedule.AwayRoom || ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

