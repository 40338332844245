import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import RoomSchedule from './Room';

export default function RoomListHarrow() {
  const { schedulesHarrow } = useContext(ScheduleContext);
  const [largeSchedules, setLargeSchedules] = useState([]);
  const [smallSchedules, setSmallSchedules] = useState([]);

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    const filterSchedules = (venueName) => {
      return schedulesHarrow.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    const large = ensureNEntries(filterSchedules('Large Community Room'));
    const small = ensureNEntries(filterSchedules('Small Community Room'));

    setLargeSchedules(large);
    setSmallSchedules(small);
  }, [schedulesHarrow]);

  function ensureNEntries(schedules) {
  
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });
  
    // Limit to the next 5 events
    const limitedSchedules = schedules.slice(0, 2);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 2) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-2 text-center">
      <div className="row">
        <div className="col">
          <RoomSchedule venueName="LARGE COMMUNITY ROOM" schedules={largeSchedules} />
        </div>
        <div className="col">
          <RoomSchedule venueName="SMALL COMMUNITY ROOM" schedules={smallSchedules} />
        </div>
      </div>
    </div>
  );
}
