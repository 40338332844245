import React from 'react';
import './style.css';

function HeaderCentre() {
  return (
    <div className="ipsc-vert-header_top">
        <div className="ipsc-vert-center">Iroquois Park Sports Centre</div>
        <div className="ipsc-vert-center_small">Today's Schedule</div>
    </div>
  );
}

export default HeaderCentre;


