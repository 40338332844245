import React, { useState } from 'react';
import Papa from 'papaparse';

const ImportCSV = ({ onImport }) => {
  const [file, setFile] = useState(null);

  const allowedVenues = [
    "Essex Arena (Libro)", "Essex Arena (Shaheen)", "Barnett Board Room", "Shaheen Community Room",
    "Harrow Arena", "Large Community Room", "Small Community Room",
    "Centennial Arena", "Exhibition Arena", "Victoria Road Rec Centre", "West End Recreation Centre (1)", "West End Recreation Centre (2)",
    "Banquet Hall", "Elora Pad", "Pad A", "Pad B",
    "West Hants Arena 1", "West Hants Field 1",
    "IPSC (1)", "IPSC (2)", "IPSC (3)", "IPSC (4)", "IPSC (5)", "IPSC (6)", "McKinney (1)", "McKinney (2)", "McKinney (3)", "Luther Vipond Memorial",
    "AOP Boardroom", "Large Mezzanine Boardroom", "Small Mezzanine Boardroom", "Whitney Hall"
  ];

  const formatTime = (timeStr) => {
    if (!timeStr) return null;
    
    // Remove any AM/PM indicators and trim whitespace
    timeStr = timeStr.replace(/\s*[AaPp][Mm]\s*$/, '').trim();
    
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeStr.split(':').map(num => parseInt(num, 10));
    
    // Pad hours and minutes with leading zeros
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = (minutes || 0).toString().padStart(2, '0');
    
    // Return in HH:mm format
    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleFileChange = (event) => {
    console.log("File selected:", event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const handleImport = () => {
    if (file) {
      console.log("Starting CSV parsing");
      Papa.parse(file, {
        header: true,
        complete: (results) => { 
          console.log("Parsing complete. Results:", results.data);

          // Process the results to ensure they are in the correct format
          const processedData = results.data.map(item => {
            const date = new Date(item.Date);
            const formattedDate = isNaN(date) ? null : date.toISOString().split('T')[0];
            const formattedStartTime = formatTime(item.StartTime);
            const formattedEndTime = formatTime(item.EndTime);
            const formattedVenue = item.Venue;

            return {
              Date: formattedDate,
              StartTime: formattedStartTime,
              EndTime: formattedEndTime,
              Type: item.Type,
              Team: item.Team,
              HomeRoom: item.HomeRoom,
              Opponent: item.Opponent,
              AwayRoom: item.AwayRoom,
              Venue: formattedVenue
            };
          });

          // Filter out entries with invalid dates or times
          const validData = processedData.filter(item => 
            item.Date && item.StartTime && item.EndTime && allowedVenues.includes(item.Venue)
          );
          
          onImport(validData);
        },
      });
    } else {
      console.log("No file selected");
    }
  };

  return (
    <div className="d-flex align-items-center">
      <input type="file" accept=".csv" onChange={handleFileChange} className="form-control" />
      <button onClick={handleImport} className="btn btn-outline-success">Import</button>
    </div>
  );
};

export default ImportCSV;
