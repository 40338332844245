import React, { useEffect, useState } from 'react';
import './style.css';

function HeaderRight() {
  const [currentTime, setCurrentTime] = useState(new Date());
    
  useEffect(() => {
      const intervalId = setInterval(() => {
          setCurrentTime(new Date());
      }, 1000);

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const formattedDate = currentTime.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
  });

  const formattedTime = currentTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
  });


  return (
    <div className="ipsc-vert-header_top">
      <div className="ipsc-vert-header_right">
        {formattedTime}
      </div>
      <div className="ipsc-vert-header_right_bottom">
        {formattedDate}
      </div>
    </div>
  );
}

export default HeaderRight;