import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import '../Jumbotron.css'; 

export default function JumbotronHarrow() {
    const { jumbotronHarrow } = useContext(ScheduleContext);
    const [latestMessage, setLatestMessage] = useState('');

    useEffect(() => {
        if (jumbotronHarrow.length > 0) {
            const latest = jumbotronHarrow[jumbotronHarrow.length - 1].description;
            setLatestMessage(latest);
        } else {
            setLatestMessage('Welcome to the Harrow and Colchester South Community Centre.');
        }
    }, [jumbotronHarrow]);

    return (
        <footer className="mt-2">
            <div className="bg-secondary text-white text-center scrolling-text"> 
                <div className="container">
                    <h1 className="h1 kanit-regular">{latestMessage}</h1>
                </div>
            </div>
            <div className="bg-transparent text-white text-center"> 
                <div className="container">
                    <br></br>
                    <br></br>
                </div>
            </div>
        </footer>
    );
}
