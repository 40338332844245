import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import '../Teams.css'; // Assuming you have a CSS file for additional styling

export default function Teams() {
    const { teams, addTeam, updateTeam, deleteTeam } = useContext(ScheduleContext);
    const [teamList, setTeamList] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [newTeam, setNewTeam] = useState({ Code: '', Name: '' });
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setTeamList(teams);
    }, [teams]);

    const handleEdit = (index) => {
        if (index === editIndex) {
            updateTeam(teamList[editIndex].Id, { Name: teamList[editIndex].Name, Code: teamList[editIndex].Code });
            setEditIndex(-1);
        } else {
            setEditIndex(index);
        }
    };

    const handleChange = (index, field, value) => {
        const updatedTeams = teamList.map((team, idx) =>
            idx === index ? { ...team, [field]: value } : team
        );
        setTeamList(updatedTeams);
    };

    const handleAddTeam = () => {
        addTeam({ Code: newTeam.Code, Name: newTeam.Name });
        setNewTeam({ Code: '', Name: '' });
    };

    const handleNewTeamChange = (field, value) => {
        setNewTeam(prev => ({ ...prev, [field]: value }));
    };

    const handleDelete = (id) => {
        deleteTeam(id);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className='container-fluid'>
            <h1 className='kanit-regular'>Team Names</h1>
            <div className="col mb-4">
                <div className="card h-100">
                    <div className="h2 btn btn-light btn-lg card-header text-primary" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
                        Team Names
                    </div>
                    <div className={`collapse ${!isCollapsed ? 'show' : ''}`}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Team Code</th>
                                            <th>Team Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teamList.map((team, index) => (
                                            <tr key={team.Id}>
                                                <td className="col-12 col-md-4 mb-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={team.Code}
                                                        disabled
                                                    />
                                                </td>
                                                <td className="col-12 col-md-5 mb-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editIndex === index ? teamList[index].Name : team.Name}
                                                        onChange={(e) => handleChange(index, 'Name', e.target.value)}
                                                        disabled={editIndex !== index}
                                                    />
                                                </td>
                                                <td className="col-12 col-md-3 mb-2">
                                                    <button className='btn btn-outline-dark' onClick={() => handleEdit(index)}>
                                                        {editIndex === index ? 'Save' : 'Edit'}
                                                    </button>
                                                    <button className='btn btn-outline-danger m-1' onClick={() => handleDelete(team.Id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="col-12 col-md-4 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={newTeam.Code}
                                                    onChange={(e) => handleNewTeamChange('Code', e.target.value)}
                                                    placeholder="New Team Code"
                                                />
                                            </td>
                                            <td className="col-12 col-md-5 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={newTeam.Name}
                                                    onChange={(e) => handleNewTeamChange('Name', e.target.value)}
                                                    placeholder="New Team Name"
                                                />
                                            </td>
                                            <td className="col-12 col-md-3 mb-2">
                                                <button className='btn btn-outline-info' onClick={handleAddTeam}>Add Team</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
