// components/CenterContent.js
import React from 'react';
import './Luther.css';

function CenterContent() {
  return (
    <div className="luther-center_content">
      <div className="luther-center">Luther Vipond Memorial Arena</div>
      <div className="luther-center_small">Current Schedule</div>
    </div>
  );
}

export default CenterContent;