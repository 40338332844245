import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTable from './ScheduleTable';
import './Luther.css';

function Luther() {
  const { fetchSchedulesWhitby } = useContext(ScheduleContext);
      
  useEffect(() => {
    const interval = setInterval(() => {
        const currentHour = new Date().getHours();
        if (!(currentHour >= 0 && currentHour < 6)) {
            fetchSchedulesWhitby();
        }
    }, 60000)

    return () => clearInterval(interval);
  });

  useEffect(() => {
      document.body.classList.add('luther-app-container');

      return () => {
          document.body.classList.remove('luther-app-container');
        };
  }, [])

  return (
    <div>
      <Header />
      <CenterContent />
      <div className="luther-title">Arena 1</div>
      <ScheduleTable />
    </div>
  );
}

export default Luther;
