import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/AuthContext';
import ScheduleEdit from '../../components/Schedule/Essex/ScheduleEdit';
import Teams from '../../components/Teams/Essex/Teams';
import Login from '../../components/Auth/Essex/Login'
import JumbotronEdit from '../../components/Jumbotron/Essex/JumbotronEdit';
import JumbotronEditHarrow from '../../components/Jumbotron/Essex/JumbotronEditHarrow';
import ScheduleEditHarrow from '../../components/Schedule/Essex/ScheduleEditHarrow';
import HeaderEdit from '../../components/Header/Essex/HeaderEdit';


export default function EssexEdit() {
    const { user, client, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && client !== 'essex') {
            alert('You do not have access to this page.');
            logout(navigate);
        }
    }, [user, client, logout, navigate]);

    if (!user) {
        return <Login />;
    }

    if (client !== 'essex') {
        return null;
    }

    return (
        <>
            <HeaderEdit />
            <ScheduleEdit />
            <JumbotronEdit />
            <br></br>
            <ScheduleEditHarrow />
            <JumbotronEditHarrow />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    )
}