import React, {useEffect, useContext} from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import HeaderRight from './HeaderRight';
import ArenaSchedule from './ArenaSchedule';
import './mck.css';
import Toyota from './toyota.png';

function MCKRight() {
  const { fetchSchedulesWhitby } = useContext(ScheduleContext);
  
  useEffect(() => {
    const interval = setInterval(() => {
        const currentHour = new Date().getHours();
        if (!(currentHour >= 0 && currentHour < 6)) {
            fetchSchedulesWhitby();
        }
    }, 60000)

    return () => clearInterval(interval);
  });

  useEffect(() => {
      document.body.classList.add('mck-app-container');

      return () => {
          document.body.classList.remove('mck-app-container');
          };
  }, [])
  

  return (
    <div className="App">
      <HeaderRight />
      <div className="mck-logos">
        <img className="mck-logo_top-toyota" src={Toyota} alt="Toyota Logo" />
      </div>
      <div className="mck-title1">Arena 3</div>
      <ArenaSchedule title="Arena 3" arenaId="McKinney (3)" className="mck-midle_main" />
    </div>
  );
}

export default MCKRight;