import React, { useContext, useState, useEffect } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import RecurringEventModal from '../Schedule/RecurringEvent/RecurringEventModal';

export default function RecurringEvents({ venueName }) {
    const { schedules, addRecurringEvent, deleteScheduleEssex } = useContext(ScheduleContext);
    const [groupedRecurringEvents, setGroupedRecurringEvents] = useState([]);
    const [showModal, setShowModal] = useState(false); // For modal visibility
    const [saveError, setSaveError] = useState(null); // To track errors
    const [isCollapsed, setIsCollapsed] = useState(false); // To toggle table visibility

    // Helper function to format time (e.g., "2:00 PM")
    const formatTime = (time) => {
        const [hour, minute] = time.split(':');
        const date = new Date();
        date.setHours(hour, minute);
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    };

    // Helper function to format date (e.g., "Jan 1, 2025")
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    };

    // Group recurring events and sort by StartDate in ascending order
    const groupRecurringEvents = (events) => {
        const grouped = events.reduce((acc, event) => {
            const key = `${event.Team}-${event.Opponent}-${event.StartTime}-${event.EndTime}`;
            const eventDayName = new Date(event.Date).toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' });

            if (!acc[key]) {
                acc[key] = {
                    Team: event.Team,
                    Opponent: event.Opponent,
                    StartTime: event.StartTime,
                    EndTime: event.EndTime,
                    Days: new Set([eventDayName]),
                    StartDate: event.Date,
                    EndDate: event.Date,
                    Count: 1,
                    Ids: [event.Id],
                };
            } else {
                acc[key].Days.add(eventDayName);
                acc[key].StartDate = acc[key].StartDate < event.Date ? acc[key].StartDate : event.Date;
                acc[key].EndDate = acc[key].EndDate > event.Date ? acc[key].EndDate : event.Date;
                acc[key].Count += 1;
                acc[key].Ids.push(event.Id);
            }
            return acc;
        }, {});

        return Object.values(grouped)
            .map(group => ({
                ...group,
                Days: Array.from(group.Days).sort(),
            }))
            .sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate)); // Sort by StartDate
    };

    useEffect(() => {
        const venueSchedules = schedules.filter(schedule => schedule.Venue === venueName && schedule.Type === 'Recurring Event');
        const grouped = groupRecurringEvents(venueSchedules);
        setGroupedRecurringEvents(grouped);
    }, [schedules, venueName]);

    const handleDelete = async (event) => {
        if (window.confirm('Are you sure you want to delete all occurrences of this recurring event?')) {
            try {
                for (const id of event.Ids) {
                    await deleteScheduleEssex(venueName, id);
                }

                // Refresh grouped events
                const venueSchedules = schedules.filter(schedule => schedule.Venue === venueName && schedule.Type === 'Recurring Event');
                const grouped = groupRecurringEvents(venueSchedules);
                setGroupedRecurringEvents(grouped);

                alert('Recurring event deleted successfully.');
            } catch (error) {
                console.error('Error deleting recurring event:', error);
                alert('Failed to delete recurring event.');
            }
        }
    };

    const handleModalSubmit = async (formData) => {
        setSaveError(null);
        try {
            await addRecurringEvent(formData);
            setShowModal(false); // Close the modal
            alert("Recurring event added. Please refresh to view changes");
        } catch (error) {
            console.error('Error adding recurring event:', error);
            setSaveError('Error adding recurring event:', error.message);
        }
    };

    return (
        <div className="container-fluid mt-4">
            <div className="card">
                <div
                    className="card-header bg-dark text-white h3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setIsCollapsed(!isCollapsed)} // Toggle collapse
                >
                    {venueName}
                </div>

                {!isCollapsed && ( // Show or hide the table body based on `isCollapsed`
                    <div className="card-body">
                        <div className="table-responsive" style={{ fontSize: '1.2rem' }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Team</th>
                                        <th>Opponent</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Days</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Occurrences</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedRecurringEvents.length > 0 ? (
                                        groupedRecurringEvents.map((event, index) => (
                                            <tr key={index}>
                                                <td>{event.Team}</td>
                                                <td>{event.Opponent}</td>
                                                <td>{formatTime(event.StartTime)}</td>
                                                <td>{formatTime(event.EndTime)}</td>
                                                <td>{event.Days.join(', ')}</td>
                                                <td>{formatDate(event.StartDate)}</td>
                                                <td>{formatDate(event.EndDate)}</td>
                                                <td>{event.Count}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDelete(event)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                No recurring events
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* Add Recurring Event Button */}
                        <button
                            className="btn btn-primary mt-3"
                            onClick={() => setShowModal(true)}
                        >
                            Add Recurring Event
                        </button>
                    </div>
                )}
            </div>

            {/* Recurring Event Modal */}
            {showModal && (
                <RecurringEventModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    handleSubmit={handleModalSubmit}
                    venueName={venueName}
                />
            )}

            {/* Display error if any */}
            {saveError && <p className="text-danger mt-3">{saveError}</p>}
        </div>
    );
}
