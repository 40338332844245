import React, { useEffect, useState, useContext } from 'react';
import './style.css';
import { ScheduleContext } from '../../../context/ScheduleContext';

function ScheduleTable({ id, className, arenaId }) {
  const { schedulesWhitby, teamsWhitby } = useContext(ScheduleContext); // Use context to get schedules and teams
      const [arenaSchedules, setArenaSchedules] = useState([]);
    
      useEffect(() => {
        const now = new Date();
        const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });
    
        // Filter schedules for each venue
        const filterSchedules = (venueName) => {
          return schedulesWhitby.filter(s => {
            if (s.Venue === venueName && s.Date === today) {
              // Combine the schedule date and EndTime to create a complete Date object
              const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
              const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds
    
              return now <= thirtyMinutesAfterEnd;
            }
            return false;
          });
        };
    
        const arena = ensureNEntries(filterSchedules(arenaId));
        setArenaSchedules(arena);
      }, [schedulesWhitby, teamsWhitby, arenaId]); // Rerender when schedules or teams context changes
    
      function ensureNEntries(schedules) {
        // Sort schedules by Date and StartTime
        schedules.sort((a, b) => {
          const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
          const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
          return dateA - dateB;
        });
    
        // Limit to the next 8 events
        const limitedSchedules = schedules.slice(0, 4);
      
        // Add placeholders if less than 8 events are found
        const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
        while (limitedSchedules.length < 4) {
          limitedSchedules.push(placeholder);
        }
      
        return limitedSchedules;
      }

      function formatTimeTo12Hour(time24) {
        if (!time24) return ''; // Handle empty or undefined time
        const [hour, minute] = time24.split(':').map(Number);
        const amPm = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
        return `${formattedHour}:${minute.toString().padStart(2, '0')}${amPm}`;
      }

  return (
    <div id={id} className={className}>
      <div className="ipsc-vert-midle">
        <div className="divTable ipsc-vert-blueTable">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead ipsc-vert-width16">Time</div>
              <div className="divTableHead ipsc-vert-width80">Teams</div>
              <div className="divTableHead ipsc-vert-width10">Room</div>
            </div>
          </div>
          <div className="divTableBody">
            {arenaSchedules.map((schedule, index) => (
              <div className="divTableRow" key={index}>
                <div className="divTableCell ipsc-vert-width14">{formatTimeTo12Hour(schedule.StartTime)}</div>
                <div className="divTableCell ipsc-vert-width80">{schedule.Team.substring(0, 26)} <br />  {schedule.Opponent.substring(0, 26)}</div>
                <div className="divTableCell ipsc-vert-width10">
                  {schedule.HomeRoom}
                  <br />
                  {schedule.AwayRoom}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleTable;