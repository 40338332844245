import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueScheduleHarrow from './VenueScheduleHarrow';
import '../Schedule.css';

function ScheduleListHarrow() {
  const { schedulesHarrow, teams } = useContext(ScheduleContext); // Use context to get schedules and teams
  const [harrowSchedules, setHarrowSchedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    const now = new Date(); 
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    // Create a map of team codes to team names
    const teamMapping = teams.reduce((map, team) => {
      map[team.Code] = team.Name;
      return map;
    }, {});
    setTeamMap(teamMapping);

    // Filter schedules for each venue
    const filterSchedules = (venueName) => {
      return schedulesHarrow.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          // Combine the schedule date and EndTime to create a complete Date object
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    const harrow = ensureFiveEntries(filterSchedules('Harrow Arena'));
    setHarrowSchedules(harrow);
  }, [schedulesHarrow, teams]); // Rerender when schedules or teams context changes

  function ensureFiveEntries(schedules) {
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });
  
    // Limit to the next 5 events
    const limitedSchedules = schedules.slice(0, 5);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 5) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-3 text-center">
      <div className="row">
        <div className="col">
          <VenueScheduleHarrow venueName="HARROW RINK" schedules={harrowSchedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}

export default ScheduleListHarrow;
