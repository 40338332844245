import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/AuthContext';
import ProjectEdit from './ProjectEdit';
import Login from '../../components/Auth/Essex/Login';
import Header from '../../components/Header/HarrisTime/Header'

export default function Project() {
    // const { user, client, logout } = useContext(AuthContext);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (user && client !== 'harristime') {
    //         alert('You do not have access to this page.');
    //         logout(navigate);
    //     }
    // }, [user, client, logout, navigate]);

    // if (!user) {
    //     return <Login />;
    // }

    // if (client !== 'essex') {
    //     return null;
    // }

    return (
        <>
            <Header />
            <ProjectEdit />
            <footer className="bg-dark text-white text-center py-4 mt-3">
                <div className="container">
                    <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
}
